import { useEffect } from "react";
import {
	SubTitle,
	Title,
	Wrapper,
	Text,
	NumberedList,
	NumberedItem,
	ItemText,
} from "./StyledPrivacyPolicy";

const PrivacyPolicy = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<Wrapper>
			<Title>Політика конфіденційності</Title>
			<Text>
				Tenzo.com (далі - «‎ми», «наш», «нас», «tenzo.com» ) - біржа
				Telegram-каналів, яка надає послуги з реалізації та/або просування
				реалізації Telegram-каналу користувачів.
			</Text>

			<Text>
				Ми, у Tenzo.com, створили цю Політику конфіденційності, мета якої –
				допомогти вам зрозуміти, які дані ми збираємо, для чого ми їх
				використовуємо та як ви можете реалізувати свої права. Важливо уважно
				прочитати цю Політику конфіденційності та чітко її зрозуміти, тому ми
				сподіваємося, що ви приділите їй час та увагу.
			</Text>

			<Text>
				Ваша конфіденційність та безпека мають першочергове значення для нас. Ми
				прагнемо захищати дані, якими ви ділитеся з нами.
			</Text>

			<SubTitle>1. Визначення</SubTitle>
			<NumberedList>
				<NumberedItem>
					1.1
					<ItemText>
						«Веб-сайт» означає веб-сайти tenzo.com за адресою https://tenzo.com
						та https://t.me/btenzo.
					</ItemText>
				</NumberedItem>
				<NumberedItem>
					1.2
					<ItemText>
						«Клієнт» означає фізичну особу, яка приймає цю Політику
						конфіденційності та замовляє Сервіс Tenzo.com.
					</ItemText>
				</NumberedItem>
				<NumberedItem>
					1.3
					<ItemText>
						«Відвідувач» означає фізичну особу, яка приймає цю Політику
						конфіденційності та відвідує Веб-сайт.
					</ItemText>
				</NumberedItem>
				<NumberedItem>
					1.4
					<ItemText>
						«Користувач» охоплює категорії Клієнта та Відвідувача одночасно.
					</ItemText>
				</NumberedItem>
				<NumberedItem>
					1.5
					<ItemText>
						«Персональні дані» означають будь-яку інформацію, що стосується
						ідентифікованої або ідентифікованої фізичної особи, наприклад, ім'я,
						логін або назву Telegram-каналу Користувача, а також інформацію про
						дії, які Користувач робить на Веб-сайті.
					</ItemText>
				</NumberedItem>
				<NumberedItem>
					1.6
					<ItemText>
						«Обробка Персональних даних» означає будь-яку дію (операцію) або
						набір дій (операцій), що виконуються з використанням засобів
						автоматизації або без використання таких засобів з Персональними
						даними, включаючи збір, запис, систематизацію, накопичення,
						зберігання, уточнення (оновлення, зміна), вилучення, використання,
						передача (поширення, надання, доступ), знеособлення, блокування,
						видалення, знищення персональних даних.
					</ItemText>
				</NumberedItem>
				<NumberedItem>
					1.7
					<ItemText>
						«Сервіс» означає послуги, що надаються Tenzo.com відповідно до Умов
						використання.
					</ItemText>
				</NumberedItem>
				<NumberedItem>
					1.8
					<ItemText>
						«Оператор» означає уповноважену Tenzo.com особу, відповідальну за
						збір та обробку персональних даних з Веб-сайту, з метою, у тому
						числі, не обмежуючись використанням Веб-сайту.
					</ItemText>
				</NumberedItem>
				<NumberedItem>
					<ItemText></ItemText>
				</NumberedItem>
			</NumberedList>

			<SubTitle>2. Загальна інформація</SubTitle>

			<NumberedList>
				<NumberedItem>
					2.1
					<ItemText>
						Настоящая Политика является юридическ и обязывающим соглашением
						между любым Пользователем и Tenzo.com, которое регулирует порядок и
						условия обработки Персональных данных Пользователей Tenzo.com.
					</ItemText>
				</NumberedItem>
				<NumberedItem>
					2.2
					<ItemText>
						Настоящая Политика является юридическ и обязывающим соглашением
						между любым Пользователем и Tenzo.com, которое регулирует порядок и
						условия обработки Персональных данных Пользователей Tenzo.com.
					</ItemText>
				</NumberedItem>

				<NumberedItem $paddingLeft="40px">
					2.2.1
					<ItemText>
						Персональные данные, которые Пользователь предоставляет о себе
						самостоятельно при заказе Сервиса или в процессе использования
						Веб-сайта https://tenzo.com и https://t.me/btenzo или Сервисов.
					</ItemText>
				</NumberedItem>
			</NumberedList>
		</Wrapper>
	);
};

export default PrivacyPolicy;
